import Image from "next/image";

const DownloadApp: React.FC = () => {
  // const openAppStore = () => {
  // 	window.open(
  // 		'https://apps.apple.com/us/app/rent80/id1578080119',
  // 		'_blank'
  // 	)
  // }

  const openGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.rent80.rentalcars",
      "_blank"
    );
  };

  return (
    <section
      id="download-app"
      className="relative flex mt-10 newsletter-gradient overflow-clip"
    >
      <div className="max-w-7xl mx-auto  items-center justify-center overflow-clip py-5 lg:py-[100px] lg:grid grid-cols-6 px-[13px]">
        <div
          className={
            "hidden lg:block absolute top-0 lg:left-0 w-full lg:h-full lg:w-full bg-no-repeat bg-cover "
            // + 'bg-[linear-gradient(180deg,rgba(217,217,217,0.4)1%,rgba(255,255,255,0)100%)] '
            // + 'lg:bg-[linear-gradient(90deg,rgba(217,217,217,0.4)1%,rgba(255,255,255,0)100%)] '
          }
        >
          <Image
            className="object-cover mix-blend-multiply"
            src={"/download-app/download-app-banner.webp"}
            alt={"Decoration"}
            fill
          />
        </div>
        <img
        loading="lazy"
          src="/download-app/mockup.png"
          height={268}
          width={132}
          alt="Mockup of a phone showing the rent80 app"
          className="hidden scale-[3.8] lg:grid -rotate-[20deg] translate-x-52 translate-y-36 -mb-28"
        />
        <div className="col-span-4 col-start-3">
          <h2 className="font-bold text-base lg:text-[40px] text-white">
            Download Rent80 App Now
          </h2>
          <p className="font-light text-xs lg:text-2xl text-[#F4F4F4] mt-2 lg:mt-7 opacity-70">
            Easily compare prices and book the best rental car with the Rent80
            app. Download now.
          </p>
          <div className="flex items-center justify-start gap-[10px] mt-[17px] lg:mt-[51px] lg:gap-[14px]">
            {/* <Image
							onClick={() => openAppStore()}
							src='/download-app/app-store.svg'
							alt='App store button'
							height={24}
							width={83}
							className='grid cursor-pointer lg:hidden'
						/> */}
            <img
            loading="lazy"
              onClick={() => openGooglePlay()}
              src="/download-app/google-play.svg"
              alt="Google play button"
              height={24}
              width={83}
              className="cursor-pointer h-[24px] w-[83px] lg:h-[42px] lg:w-[144px] z-20"
            />
            {/* <Image
							onClick={() => openAppStore()}
							src='/download-app/app-store.svg'
							alt='App store button'
							height={42}
							width={144}
							className='hidden cursor-pointer lg:grid'
						/> */}
          </div>
        </div>
      </div>
      <img
      loading="lazy"
        src="/download-app/mockup.svg"
        height={268}
        width={132}
        alt="Mockup of a phone showing the rent80 app"
        className="lg:hidden scale-[1.4] grid rotate-[20deg]  -mb-28"
      />
    </section>
  );
};

export default DownloadApp;
